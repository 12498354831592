import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import About from "../containers/about";

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="A propos - Mecaniques"
        description="Mécaniques est un blog traitant de physique théorique, relativité, mécanique quantique et autres sujets passionnants"
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
