import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import SocialProfile from "../../components/social-profile/social-profile";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io";
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style";

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "https://www.facebook.com/mecaniques.org",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/mecaniques101",
    tooltip: "Twitter",
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `);

  return (
    <AboutWrapper>
      <AboutDetails>
        <h2>Bonjour et bienvenue sur mon blog</h2>
        <p>
          <i>Mécaniques</i> est un blog associé au livre du même nom dont je
          finalise actuellement l'écriture. On y parle de physique théorique
          principalement : relativité restreinte et générale, mécanique
          quantique, et quelques autres sujets un peu plus avancés.
        </p>
        <p>
          J'essaye, dans la mesure du possible, de présenter sous une
          perspective originale, souvent vulgarisée mais toujours rigoureuse,
          des résultats déjà connus, d'autres non, qui éclairent sur des
          questions que pose la physique au sujet de la structure du réel, sur
          la nature du temps, de l'espace, et celle des objets qu'elle manipule.
        </p>
        <p>
          On pourrait ici me qualifier d'ontologiste, si ce terme était en
          usage. De manière plus convenue, je suis autant informaticien de
          métier, que physicien et philosophe de passion, et je mêle ces
          disciplines pour apporter des réponses aux questions évoquées au
          dessus. En vous souhaitant une très bonne lecture.
        </p>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
